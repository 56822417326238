<template>
  <TepmplateBlock
    mod-class
    content-class="mytechnique-wrap mt-5"
    title-page="Добавление нового устройства"
  >
    <div class="scroll-container">
      <b-alert v-if="allOffices && allOffices.length < 1" show variant="info">
        Вы не добавили ещё ни одного офиса. Чтобы это сделать - перейдите
        <router-link to="/office">по ссылке</router-link>
      </b-alert>
      <vuescroll>
        <div
          class="d-flex justify-content-center align-items-center mb-5 mt-5"
          v-if="isShowSpinner"
        >
          <b-spinner />
        </div>

        <div class="add-item" v-else>
          <form @submit.prevent="createTechnique">
            <div class="add-item__img-load-wrap">
              <label class="file-select">
                <div v-if="!src" class="select-button"></div>
                <span v-if="value">{{ value.name }}</span>
                <span v-if="!src"
                  >Загрузите фотографию устройства
                  <help-popover
                    message="Рекомендуется фотографировать аппарат, заводя его через мобильное приложение."
                  />
                </span>
                <img class="preview-image" v-else :src="src" alt="" />
                <input type="file" @change="handleFileChange" />
              </label>
            </div>

            <label class="form-group__label required-label"
              >Тип устройства</label
            >
            <b-select
              class="form-control form-group"
              :class="{ invalid: typeof type !== 'string' }"
              v-model="type"
              style="background-color: var(--main-card-color)"
            >
              <option
                v-for="item in deviceType"
                :key="item.id"
                :value="item.value"
                style="background-color: var(--main-card-color)"
              >
                {{ item.name }}
              </option>
            </b-select>

            <div class="position-relative mb-3">
              <label class="form-group__label required-label">
                Модель
              </label>
              <b-input
                v-model="title"
                class="search-technique-input form-control"
                :class="title.length > 2 ? 'valid' : 'invalid'"
                :debounce="300"
                @focus="focusOnInput = true"
                @blur="hideTechList"
                @update="getTechniqueName"
                autocomplete="off"
              />

              <div
                class="searched-tech custom-scrollbar"
                v-if="techniqueList.length && focusOnInput"
              >
                <div v-if="techniqueList.length">
                  <div
                    class="d-flex justify-content-center"
                    v-if="isTechniqueLoading"
                  >
                    <b-spinner size="sm" />
                  </div>

                  <div
                    v-for="item in techniqueList"
                    :key="item.id"
                    class="c-pointer searched-tech__item"
                    @click="setTechnique(item)"
                  >
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>

            <CustomInput
              v-model="serialNum"
              label="Серийный номер"
              :required="true"
              :is-need-validation="true"
            >
              <template #popover>
                <help-popover
                  message="Серийный номер является уникальным и должен быть заполнен максимально правильно."
                />
              </template>
            </CustomInput>

            <!--            <b-form-group>-->
            <!--              <label>Тип печати</label>-->
            <!--              <b-form-radio v-model="color_attr" :value="true"-->
            <!--                >Цветной</b-form-radio-->
            <!--              >-->
            <!--              <b-form-radio v-model="color_attr" :value="false"-->
            <!--                >Черно-белый</b-form-radio-->
            <!--              >-->
            <!--            </b-form-group>-->

            <!--            <CustomInput v-model="device.resource" label="Ресурс">-->
            <!--              <template #popover>-->
            <!--                <help-popover-->
            <!--                  message="Рекомендованный производителем максимальный ресурс(время службы) или ваш-->
            <!--                прогноз. При достижении лимита - программа будет напоминать об этом"-->
            <!--                />-->
            <!--              </template>-->
            <!--            </CustomInput>-->

            <InputWithFocus
              @focus="onFocus"
              type="number"
              v-model="wear"
              label="Пробег(ч/б)"
              :required="true"
              :is-need-validation="true"
            >
              <template #popover>
                <help-popover
                  message="Кол-во ч/б страниц, напечатанных аппаратом."
                />
              </template>
            </InputWithFocus>

            <InputWithFocus
              @focus="onFocus"
              type="number"
              v-model="colorWear"
              label="Пробег (цвет)"
              :required="true"
              :is-need-validation="true"
            >
              <template #popover>
                <help-popover
                  message="Кол-во цветных страниц, напечатанных аппаратом."
                />
              </template>
            </InputWithFocus>

            <CustomInput v-model="specialMarks" label="Инвентарный номер" />

            <CustomInput v-model="stickerNum" label="Номер стикера" />

            <!--            <div class="geotag-block">-->
            <!--              <CustomInput @input="onInput" v-model="geoTagText" label="Теги" />-->
            <!--              <div-->
            <!--                v-if="autocomplete && geoTagText.length"-->
            <!--                class="autocomplete"-->
            <!--                :class="getClass"-->
            <!--              >-->
            <!--                <div-->
            <!--                  class="autocomplete-item"-->
            <!--                  v-for="value in autocompleteTags"-->
            <!--                  :key="value.id"-->
            <!--                  @click="onAutocompleteItemClick(value)"-->
            <!--                >-->
            <!--                  {{ value.title }}-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div @click="addGeoTag" class="geotag-block__btn">-->
            <!--                <div class="geotag-block__btn__text">+</div>-->
            <!--              </div>-->
            <!--            </div>-->

            <!--            <div class="geotags-items">-->
            <!--              <div-->
            <!--                class="geotags-items__item"-->
            <!--                v-for="(item, index) in device.tags"-->
            <!--                :key="index"-->
            <!--              >-->
            <!--                {{ item }}-->
            <!--                <span-->
            <!--                  class="ml-3"-->
            <!--                  style="cursor:pointer"-->
            <!--                  @click="deleteTag(item)"-->
            <!--                  >x</span-->
            <!--                >-->
            <!--              </div>-->
            <!--            </div>-->

            <div class="admin-panel">
              <b-row>
                <b-col class="form-group" :lg="12">
                  <label class="form-group__label">Компания</label>
                  <b-select
                    @change="searchRootOffices"
                    v-model="companyId"
                    class="form-control form-control-sm form-mytechnique__search"
                    style="background: var(--main-card-color)"
                  >
                    <option
                      v-for="company in concatCompanies"
                      :value="company.id"
                      :key="company.id"
                      >{{ company.brand }}
                    </option>
                  </b-select>
                </b-col>

                <b-col class="form-group" :lg="12">
                  <label class="form-group__label">Офис</label>
                  <b-select
                    v-model="officeId"
                    class="form-control form-control-sm form-mytechnique__search"
                    style="background: var(--main-card-color)"
                  >
                    <option
                      v-for="office in allOffices"
                      :value="String(office.id)"
                      :key="office.id"
                      >{{ office.name }}
                    </option>
                  </b-select>
                </b-col>
              </b-row>
            </div>

            <!--                        <FormSelect-->
            <!--                          v-model="device.office"-->
            <!--                          title="Офис"-->
            <!--                          :options="officeOptions"-->
            <!--                          @chosen="setOfficeValue"-->
            <!--                          :required="true"-->
            <!--                          :is-need-validation="true"-->
            <!--                        >-->
            <!--                          <template #title-append>-->
            <!--                            <help-popover-->
            <!--                              message="Аппарат не может просто быть. Он обязательно должен быть прикреплен к локации. Выберете офис в котором находится данный аппарат"-->
            <!--                            />-->
            <!--                          </template>-->
            <!--                        </FormSelect>-->
            <!-- <b-form-text class="form-group__label mb-2">Локация</b-form-text>
        <b-form-textarea
          id="textarea-rows"
          placeholder="Адрес офиса, этаж, № офиса"
          rows="8"
          class="add-item__textarea w-100"
        ></b-form-textarea> -->
            <!--            <div-->
            <!--              @click="toggleModal(true)"-->
            <!--              class="btn btn-second add-item__btn"-->
            <!--              title="Добавление характеристик устройства"-->
            <!--            >-->
            <!--              Добавить атрибуты-->

            <!--              <help-popover-->
            <!--                :bg-white="true"-->
            <!--                message="Атрибуты - например скорость печати. Или дополнительное оборудование(лицензии) к технике"-->
            <!--              />-->
            <!--            </div>-->
            <button type="submit" class="btn btn-second add-item__btn">
              Добавить устройство
            </button>
          </form>
        </div>
      </vuescroll>
    </div>
    <!--    <div-->
    <!--      @click="onWrapperClick"-->
    <!--      v-if="showAttributes"-->
    <!--      class="attributes-modal__wrapper"-->
    <!--    >-->
    <!--      <div @click="onModalClick" class="attributes-modal">-->
    <!--        <img-->
    <!--          @click="showAttributes = false"-->
    <!--          :src="closeIcon"-->
    <!--          class="close-icon"-->
    <!--          alt=""-->
    <!--        />-->

    <!--        &lt;!&ndash;        <div class="attributes-modal__header">&ndash;&gt;-->
    <!--        &lt;!&ndash;          <CustomInput v-model="attributeTitle" label="Название параметра" />&ndash;&gt;-->
    <!--        &lt;!&ndash;          <CustomInput v-model="attributeValue" label="Значение параметра" />&ndash;&gt;-->
    <!--        &lt;!&ndash;        </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;        <button&ndash;&gt;-->
    <!--        &lt;!&ndash;          class="btn btn-second add-item__btn mb-3 w-50 d-block mx-auto"&ndash;&gt;-->
    <!--        &lt;!&ndash;          @click="onAddAttributeClick"&ndash;&gt;-->
    <!--        &lt;!&ndash;          title="Добавление характеристик устройства"&ndash;&gt;-->
    <!--        &lt;!&ndash;        >&ndash;&gt;-->
    <!--        &lt;!&ndash;          Добавить атрибут&ndash;&gt;-->
    <!--        &lt;!&ndash;        </button>&ndash;&gt;-->
    <!--        &lt;!&ndash;        <AttributesTable&ndash;&gt;-->
    <!--        &lt;!&ndash;          page="NewItem"&ndash;&gt;-->
    <!--        &lt;!&ndash;          @delete-item="deleteTableItem"&ndash;&gt;-->
    <!--        &lt;!&ndash;          v-if="tableData.length > 0"&ndash;&gt;-->
    <!--        &lt;!&ndash;          :options="tableData"&ndash;&gt;-->
    <!--        &lt;!&ndash;        />&ndash;&gt;-->
    <!--        &lt;!&ndash; <button-->
    <!--          v-if="tableData.length"-->
    <!--          class="btn btn-second add-item__btn mb-3 w-50 d-block mx-auto"-->
    <!--          @click="addAttributesData"-->
    <!--        >-->
    <!--          Добавить атрибуты-->
    <!--        </button> &ndash;&gt;-->
    <!--      </div>-->
    <!--    </div>-->
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import CustomInput from "../../components/Form/FormElement/Input";
import InputWithFocus from "../../components/Form/FormElement/InputWithFocus";
// import FormSelect from "../../components/Form/FormSelect";
// import AttributesTable from "../../components/AttributesTable";
import RequestManager from "../../function/request/RequestManager";
import { mapActions, mapGetters } from "vuex";
import { GET_ALL_OFFICES, GET_ALL_TAGS } from "../../store/types";
import vuescroll from "vuescroll";
import HelpPopover from "../../components/HelpPopover";
import {
  CompanyApi,
  CounterApi,
  DeviceApi,
  OfficeApi,
  FilesApi
} from "@/function/request/apiV2";

let officesList = undefined;

export default {
  name: "NewItems",
  components: {
    HelpPopover,
    // FormSelect,
    CustomInput,
    InputWithFocus,
    TepmplateBlock,
    // AttributesTable,
    vuescroll
  },
  props: {
    value: File
  },
  data() {
    return {
      file: undefined,
      CounterApi: new CounterApi(),
      companyApi: new CompanyApi(),
      officeApi: new OfficeApi(),
      deviceApi: new DeviceApi(),
      filesApi: new FilesApi(),
      wear: 0,
      colorWear: 0,
      technique: null,
      techniqueList: [],
      focusOnInput: false,
      isTechniqueLoading: false,
      tableData: [],
      closeIcon: require("../../resource/img/close.svg"),
      src: null,
      showAttributes: false,
      attributeTitle: "",
      attributeValue: "",
      autocomplete: false,
      geoTagText: "",
      color_attr: false,
      isShowSpinner: false,
      currentUser: JSON.parse(window.localStorage.getItem("currentUser")),

      deviceType: [
        {
          value: "printer",
          name: "Принтер",
          id: 1
        },
        {
          value: "scanner",
          name: "Сканнер",
          id: 2
        },
        {
          value: "copier",
          name: "Копир",
          id: 3
        },
        {
          value: "mfp",
          name: "МФУ",
          id: 4
        }
      ],

      // device: {
      //   picture: "",
      //   title: "",
      //   status: "",
      //   type: "",
      //   serial_num: "",
      //   office: 0,
      //   resource: "",
      //   wear: 0,
      //   wear_color: 0,
      //   tags: [],
      //   special_marks: "",
      //   attributes: "",
      //   technique_id: undefined
      // },

      limit: 500,
      allCompanies: [],
      allCompaniesByParams: [],
      allOffices: [],
      techniqueId: "",
      officeId: "",
      title: "",
      type: [],
      serialNum: "",
      specialMarks: "",
      stickerNum: "",
      companyId: "",
      picturePath: ""
    };
  },
  watch: {
    tableData(value) {
      this.device.attributes = value;
    }
  },
  computed: {
    concatCompanies() {
      return [...this.allCompaniesByParams, ...this.allCompanies];
    },

    ...mapGetters(["officeList", "tagsList"]),
    ...mapGetters("devices", ["devicesTypesList", "devicesStatusList"]),
    // geoTags() {
    //   return this.tagsList.results;
    // },
    getClass() {
      return this.autocomplete ? "active" : "";
    },
    // getSelectedTechnique() {
    //   const deviceTitle = this.device?.title?.trim().toLowerCase();
    //   const selectedTechnique = this.technique?.title?.trim().toLowerCase();
    //   return deviceTitle === selectedTechnique;
    // },
    checkRequiredFields() {
      const errors = [];

      const titleNotValid = this.title?.length
        ? undefined
        : "Поле 'Модель' не заполнено";

      const serialNumNotValid = this.device?.serial_num?.length
        ? undefined
        : "Поле 'Серийный номер' не заполнено'";

      const typeDeviceNotValid = this.type?.length
        ? undefined
        : "Не выбран тип устройства";

      const officeNotValid = this.officeId !== 0 ? undefined : "Не выбран офис";

      errors.push(
        titleNotValid,
        serialNumNotValid,
        typeDeviceNotValid,
        officeNotValid
      );

      return errors.filter(el => el !== undefined);
    },
    // autocompleteTags() {
    //   return this.geoTags.filter(
    //     elem =>
    //       elem.title.slice(0, this.geoTagText.length).toLowerCase() ===
    //       this.geoTagText.toLowerCase()
    //   );
    // },
    // officeOptions() {
    //   const options = [];
    //   if (this.officeList !== undefined) {
    //     this.officeList.forEach(office => {
    //       options.push({ text: office.office_name, value: office.id });
    //     });
    //   }
    //   return options;
    // },
    deviceTypesOptions() {
      const options = [];
      for (let key in this.devicesTypesList) {
        options.push({ text: this.devicesTypesList[key], value: key });
      }
      return options;
    }
  },

  methods: {
    async getRootCompanies() {
      const { items } = await this.companyApi.api.listCompanies({
        limit: this.limit,
        crmManagerId: this.currentUser.id
      });
      this.allCompanies = items;
      // await this.getRootOffices();
    },

    async getRootCompaniesByParams() {
      console.log(this.currentUser);
      const { items } = await this.companyApi.api.listCompanies({
        limit: this.limit,
        ids: [this.currentUser.company]
      });
      this.allCompaniesByParams = items;
    },

    async getRootOffices() {
      const { items } = await this.officeApi.api.listOffice({
        limit: this.limit,
        companyIds: [parseInt(this.companyId)]
      });
      officesList = items;
      this.allOffices = officesList.filter(
        office => office.companyId === parseInt(this.companyId)
      );
    },

    async searchRootOffices() {
      await this.getRootOffices();
      this.officeId = String(this.allOffices[0].id);
    },

    onFocus(e) {
      e.target.select();
    },

    ...mapActions({ GET_ALL_TAGS, GET_ALL_OFFICES }),
    hideTechList() {
      setTimeout(() => {
        this.focusOnInput = false;
      }, 200);
    },

    async getTechniqueName() {
      if (this.title?.length >= 2) {
        try {
          this.isTechniqueLoading = true;
          const searchParams = {
            title: this.title,
            limit: 50
          };
          const { items } = await RequestManager().techniqueApi.techniqueList(
            searchParams
          );
          this.techniqueList = items?.length ? items : [];
        } finally {
          this.isTechniqueLoading = false;
        }
      }
    },

    setTechnique(technique) {
      this.focusOnInput = false;
      this.title = technique.title;
      this.technique = technique;
      this.techniqueId = technique?.id ?? undefined;
      this.getTechniqueName();
    },

    // // deleteTableItem(item) {
    // //   this.tableData = this.tableData.filter(elem => elem !== item);
    // // },
    // // deleteTag(item) {
    // //   this.device.tags = this.device.tags.filter(elem => elem !== item);
    // // },
    // // toggleModal(value) {
    // //   this.showAttributes = value;
    // // },
    // // onWrapperClick() {
    // //   this.toggleModal(false);
    // // },
    // // onModalClick(e) {
    // //   e.stopPropagation();
    // // },
    // // onAddAttributeClick() {
    // //   if (this.attributeTitle.length > 0 && this.attributeValue.length > 0) {
    // //     this.tableData = [
    // //       ...this.tableData,
    // //       { title: this.attributeTitle, description: this.attributeValue }
    // //     ];
    // //     this.attributeTitle = "";
    // //     this.attributeValue = "";
    // //   }
    // // },
    //
    // addAttributesData() {
    //   this.device.attributes = this.tableData;
    //   // this.showAttributes = false;
    // },
    //
    // setOfficeValue(data) {
    //   this.device.office = data;
    // },
    // setTypeValue(data) {
    //   this.type = data;
    // },
    // onInput() {
    //   this.autocomplete = this.geoTagText.length > 0;
    // },
    //
    // onAutocompleteItemClick(value) {
    //   this.geoTagText = value.title;
    //   this.autocomplete = false;
    // },

    handleFileChange(e) {
      this.src = URL.createObjectURL(e.target.files[0]);
      //this.device.picture = e.target.files[0];
      console.log(e.target.files[0]);
      this.file = e.target.files[0];
    },

    validate() {},

    getFormData() {
      return { ...this.form };
    },

    fetchData() {
      const optionsArray = [];
      for (let key in this.devicesTypesList.devicesTypes) {
        optionsArray.push({
          text: this.devicesTypesList.devicesTypes[key],
          value: key
        });
      }
      this.options = optionsArray;
    },

    async createTechnique() {
      let form = {
        officeId: this.officeId,
        title: this.title,
        type: this.type,
        status: "working",
        serialNum: this.serialNum,
        specialMarks: this.specialMarks,
        stickerNum: this.stickerNum,
        picturePath: this.picturePath,
        techniqueId: this.techniqueId
      };

      if (this.file) {
        console.log(this.file);
        const response = await RequestManager().filesApi.upload("/", this.file);
        console.log(response);
        console.log(form);

        let a = true;
        if (a) {
          form.picturePath = response[0].path;
        }
      }
      let mydebug = false;
      if (!mydebug) {
        this.deviceApi.api.createDevice({ body: form }).then(res => {
          this.CounterApi.api.deviceCountersReset(res.id, {
            codeName: "wear",
            value: Number(this.wear)
          });

          this.CounterApi.api.deviceCountersReset(res.id, {
            codeName: "color",
            value: Number(this.colorWear)
          });

          localStorage.setItem("mode", "createTechnique");

          this.$router.push({ path: "/my-techique/" + res.id });
          //   this.$router.push({ name: "MyTechnique", query:{id: res.id}, params: { mode: 'create' }});
        });
        // } catch (e) {
        //   this.$bvToast.toast(e.error.message, {
        //   const formData = new FormData();
        //   for (let key in this.device) {
        //     if (key === "tags") {
        //       this.device[key].forEach(elem => {
        //         formData.append(
        //           "tag_ids",
        //           this.geoTags.find(item => item.title === elem).id
        //         );
        //       });
        //     } else if (key === "attributes") {
        //       formData.append(key, JSON.stringify(this.device.attributes));
        //     } else if (key === "office") {
        //       formData.append("office_id", this.device[key]);
        //     } else if (key === "technique_id") {
        //       if (this.device.technique_id) {
        //         formData.append("technique_id", this.device[key]);
        //       }
        //     } else {
        //       formData.append(key, this.device[key]);
        //     }
        //   }
      }
    }

    // RequestManager()
    //   .addNewDevice(formData)
    //   .then(res => {
    //     if (res?.success === false) {
    //       const errors = res.logError?.response?.data;
    //       const allError = JSON.stringify(errors);
    //       this.$bvToast.toast(allError, {
    //         title: "Error",
    //         variant: "danger"
    //       });
    //     } else {
    //       this.$router.push({ path: "/my-techique/" +res.id });
    //       this.CounterApi.api.deviceCountersReset(res.id, {
    //         codeName: "wear",
    //         value: Number(this.wear)
    //       });
    //       this.CounterApi.api.deviceCountersReset(res.id, {
    //         codeName: "color",
    //         value: Number(this.colorWear)
    //       });
    //     }
    //   })
    // .catch(() => (this.isShowSpinner = false))
    // .finally(() => (this.isShowSpinner = false));
    // } else {
    //   const message = this.checkRequiredFields?.join(", ");
    //   this.$bvToast.toast(message, {
    //     variant: "danger",
    //     title: "Ошибка"
    //   });
    // } finally {
    //   this.isShowSpinner = false;
    // }
  },

  created() {
    this.getRootCompanies();
    if (this.currentUser.company) {
      this.getRootCompaniesByParams();
    }

    this.companyId = this.currentUser.company;

    this.searchRootOffices();
    console.log("this.allOffices", this.allOffices);
    //  this.officeId =  String(this.allOffices[0].id) ;
  },

  mounted() {
    this.fetchData();
  }
};
</script>

<style lang="scss" scoped>
.search-technique-input {
  position: relative;
}

.searched-tech {
  z-index: 10;
  position: absolute;
  background-color: white;
  width: 100%;
  border: 1px #80b5c8ff solid;
  padding: 10px 0;
  border-radius: 4px;
  max-height: 250px;
  overflow: auto;

  &__item {
    padding: 5px 10px;
    font-size: 14px;

    &:hover {
      background-color: #80b5c8ff;
      color: white;
    }
  }
}

.close-icon {
  width: 20px;
  position: absolute;
  right: 5px;
  cursor: pointer;
  top: 5px;
  // top: -2px;
}

.scroll-container {
  width: 500px !important;
  margin: 0 auto;
  overflow: hidden;
  // max-height: 605px;
}

.attributes-modal__wrapper {
  top: 0;
  left: 0;
  //background: transparent;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 100%;
  height: 100vh;

  .attributes-modal {
    position: absolute;
    width: 70%;
    padding: 20px;
    min-height: 100px;
    background: var(--main-card-color);
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
    border: 3px solid #cecece33;

    border-radius: 10px;
    top: 50%;
    left: 49.5%;
    transform: translate(-50%, -50%);

    &__header {
      display: flex;
      justify-content: space-between;

      div {
        flex-basis: 49%;
      }
    }
  }
}

.autocomplete {
  position: absolute;
  top: 84px;
  width: 100%;
  border: 1px solid rgba(206, 206, 206, 0.2);
  border-radius: 0 0 10px 10px;
  background: #f5f5f5;
  display: none;
  z-index: 100;

  &.active {
    display: block;
  }

  .autocomplete-item {
    padding: 3px 10px;
  }

  .autocomplete-item:hover {
    background: #495057;
    color: white;
  }
}

.add-item {
  margin: 0 auto;
  padding: 38px 72px 36px;
  width: 100%;
  max-width: 498px;
  min-height: 672px;
  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
}

.add-item__textarea {
  background: rgba(206, 206, 206, 0.2);
  padding: 20px 15px;
  margin-bottom: 24px;

  font-size: 14px;
  line-height: 16px;
  font-weight: 500;

  &::placeholder {
    color: #cfcfcf;
  }
}

.form-group__label {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;

  color: var(--second-main-color) !important;
}

.add-item__img-load-wrap {
  margin-bottom: 30px;
}

.add-item__btn {
  max-width: 100%;
}

.file-select {
  cursor: pointer;
  display: flex;
  align-items: center;

  font-size: 12px;
  line-height: 14px;

  color: var(--second-main-color);
}

.file-select > .select-button {
  background-image: url("../../resource/img/image-load.svg");
  background-repeat: no-repeat;
  font-size: 10px;
  width: 95px;
  height: 85px;
  cursor: pointer;
  margin-right: 17px;
}

.preview-image {
  // width: 95px;
  display: block;
  margin: 0 auto;
  max-height: 85px;
  cursor: pointer;
}

.file-select > input[type="file"] {
  display: none;
}

.geotag-block {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__btn {
    position: absolute;
    top: 38px;
    right: -50px;
    display: flex;
    justify-content: center;
    width: 36px;
    height: 34px;
    align-items: center;
    border: 2px solid #016b90;
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;

    &__text {
      color: #016b90;
      font-size: 50px;
    }
  }
}

.form-group__label {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: var(--second-main-color);
  position: relative;
}

.required-label:after {
  content: "*";
  font-size: 26px;
  color: red;
  position: absolute;
}

.form-control {
  background: var(--auth-input-bg);
  border: 1px solid rgba(206, 206, 206, 0.2);
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 56px;

  &:focus {
    background-color: var(--auth-input-bg);
    color: var(---auth-input-bg);
  }
}

.form-control.invalid {
  border: 1px rgb(245, 0, 0) solid;
}

.form-control.invalid:focus {
  box-shadow: 0 0 0 3px rgba(245, 0, 0, 0.2);
}

.form-control.valid {
  border: 1px rgb(40, 167, 69) solid;
}

.form-control.valid:focus {
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.2);
}

.geotags-items {
  display: flex;
  position: relative;
  left: -5px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;

  &__item {
    margin-bottom: 10px;
    border: 2px solid #e6f1f4;
    font-size: 12px;
    background: #e6f1f4;
    padding: 5px 10px;
    border-radius: 10px;
    margin-left: 5px;
  }
}

.form-control {
  background-color: var(--main-card-color);
  &:focus {
    background-color: var(--main-card-color);
    color: var(--main-text-color);
  }
}

.custom-select {
  background-color: var(--main-card-color);
  color: var(--grey-text-for-input);

  &:focus {
    background-color: var(--grey-text-for-input);
  }
}
</style>
